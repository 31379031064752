<template>
  <section class="main">
    <el-form
      :label-position="'left'"
      label-width="150px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 1000px"
    >
      <el-form-item label="subjectCourseId">
        <el-input v-model="paper.subjectCourseId"> </el-input>
      </el-form-item>
      <el-form-item label="id">
        <el-input v-model="paper.paperId" disabled> </el-input>
      </el-form-item>
      <el-form-item label="试卷标题">
        <el-input v-model="paper.name"> </el-input>
      </el-form-item>
      <el-form-item label="试卷类型">
        <el-input v-model="paper.type" disabled> </el-input>
      </el-form-item>
      <br />
      <br />
      <div class="chapter-title">试卷章节</div>
      <br />
      <br />
      <div v-for="(item, index) in paper.chapters" :key="index">
        <el-row type="flex" class="chapter-content">
          <el-col :span="24">
            <el-form-item label="章节名称">
              <el-input v-model="item.name"> </el-input>
            </el-form-item>
            <el-form-item label="章节描述">
              <el-input v-model="item.desc"> </el-input>
            </el-form-item>
            <el-form-item label="问题ID">
              <el-input v-model="item.questionIdsStr"> </el-input>
            </el-form-item>
            <el-form-item label="章节分数">
              <el-input v-model="item.presetScore"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="button-item">
            <el-row>
              <el-button type="primary" icon="el-icon-circle-plus" @click="onAdd(index)" circle></el-button>
              <el-button type="danger" icon="el-icon-remove" @click="onDelete(index)" circle></el-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-form-item>
        <el-button type="primary" @click="onSave">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { tikuApi } from "@/api/tikuApi";
import msgLogger from "@/util/msgLogger.js";

export default {
  data() {
    return {
      pageStatus: "edit",
      paper: {
        type: 1,
        chapters: [
          {
            name: "",
            desc: "",
            questionIdsStr: "",
            presetScore: 0.0,
          },
        ],
      },
    };
  },
  methods: {
    onAdd(index) {
      this.paper.chapters.splice(index + 1, 0, { name: "", desc: "", questionIdsStr: "", presetScore: 0.0 });
    },
    onDelete(index) {
      if (this.paper.chapters.length <= 1) {
        msgLogger.warn("最后一个chapter不可以删除");
        return;
      }
      this.paper.chapters.splice(index, 1);
    },
    onSave() {
      tikuApi.addEditPaper(this.paper).then((ret) => {
        console.log(ret);
        const { code: code, msg: msgStr, data: data } = ret;
        if (code == 0 && data) {
          msgLogger.succeed("保存成功");
          this.modelToVO(data);
        } else {
          msgLogger.error("保存失败");
        }
      });
    },
    modelToVO(data) {
      this.paper = data;
      for (let chapter of this.paper.chapters) {
        let strValue = chapter.questionIds.join(",");
        chapter.questionIdsStr = strValue;
      }
    },
  },
  mounted() {
    const id = this.$route.query.id;
    const subjectCourseId = this.$route.query.subjectCourseId;
    if (id && id > 0 && subjectCourseId && subjectCourseId > 0) {
      this.pageStatus = "edit";
      tikuApi.getPaper(subjectCourseId, id).then((ret) => {
        console.log("paper", ret);
        let { code: code, data: data } = ret;
        if (code == 0 && data) {
          this.modelToVO(data);
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.chapter-title {
  float: left;
}
.chapter-content {
  border: 1px solid rgb(7, 7, 7);
  margin: 10px;
  padding: 20px;
}
.button-item {
  align-items: center;
  justify-content: center;
}
</style>
